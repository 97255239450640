<template>
    <BlockContainer
        :background-color="prodFilter.backgroundColor"
        :bottom-angle="prodFilter.bottomAngle"
        :angle-background-color="prodFilter.nextBackgroundColor"
        :id="'product-filter-' + slugify(prodFilter.heading)"
    >
        <div class="py-8 lg:py-28">
            <div class="flex flex-row gap-x-8">
                <div class="hidden w-1/4 lg:block"></div>
                <h2 class="mb-6 flex-1 text-center lg:pl-[29px] lg:text-left">
                    {{ prodFilter.heading }}
                </h2>
            </div>
            <ProductFilter
                v-editable="blok"
                :type="prodFilter.type"
                :industry="prodFilter.industry"
            >
                <template
                    v-if="!prodFilter.showCta && prodFilter.industry != ''"
                    #product-filter-cta
                >
                    <IndustryCta :page-name="prodFilter.industry" />
                </template>
                <template v-else-if="prodFilter.showCta" #product-filter-cta>
                    <ProductFilterCta
                        :theme="prodFilter.ctaTheme"
                        :image="prodFilter.ctaImage"
                        :heading="prodFilter.ctaHeading"
                        :text="prodFilter.ctaText"
                        :link-label="prodFilter.ctaLink.label"
                        :link-url="prodFilter.ctaLink.url"
                    />
                </template>

                <template v-if="prodFilter.showCta" #product-filter-cta-mobile>
                    <ProductFilterCta
                        :theme="prodFilter.ctaTheme"
                        :image="prodFilter.ctaImage"
                        :heading="prodFilter.ctaHeading"
                        :text="prodFilter.ctaText"
                        :link-label="prodFilter.ctaLink.label"
                        :link-url="prodFilter.ctaLink.url"
                    />
                </template>
            </ProductFilter>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import ProductFilterType from '~/types/ProductFilterType';
import LinkType from '~/types/LinkType';
import ProductFilterCta from '~/components/page-building/product-filter/components/ProductFilterCta.vue';
import IndustryCta from '~/components/page-building/product-filter/components/IndustryCta.vue';
import ProductFilter from '~/components/page-building/product-filter/ProductFilter.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const prodFilter = computed<ProductFilterType>(() => {
    return {
        heading: props.blok.heading,
        type: props.blok.type,
        industry: props.blok.industry,
        showCta: props.blok.show_cta,
        ctaTheme: props.blok.cta_theme || 'dark',
        ctaImage: props.blok.cta_image
            ? {
                  alt: props.blok.cta_image.alt,
                  src: props.blok.cta_image.filename,
              }
            : null,
        ctaHeading: props.blok.cta_heading,
        ctaText: richTextToHtml(props.blok.cta_text) as string,
        ctaLink: {
            label: props.blok.cta_link_label,
            url: '/' + props.blok.cta_link.cached_url,
            target: props.blok.cta_link.target,
            vEditable: props.blok.cta_link,
        } as LinkType,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
